import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from '../src/components/app';
import * as serviceWorker from './serviceWorker';
import config from './config';

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={config.oauth2.clientId}>
          <App />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
