import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const styles = () => ({
  theme: theme,
  root: {
    display: 'flex',
  },
  toolbarTitle: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2, 2)
  },
  googleSignIn: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  circularProgress: {
    margin: theme.spacing(0, 0, 0, 2)
  }
});

export default {
  useAllStyles: makeStyles(styles),
  styles,
};
