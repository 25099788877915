import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {AppBar, Typography, Snackbar, CircularProgress} from '@mui/material';
import {BrowserRouter as Router, Link} from "react-router-dom";
import styles from '../styles/styles';
import {GoogleLogin} from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';
import MuiAlert from '@mui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function App() {
  const classes = styles.useAllStyles();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [snackBarEnabled, setSnackBarEnabled] = React.useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = React.useState('');
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  const responseSuccessGoogle = (googleUser) =>  {
    const token = jwtDecode(googleUser.credential);

    // Google provides this in seconds
    // but Date needs it in ms
    const expiration = new Date(token.exp * 1000);
    const expiresString = expiration.toUTCString();
    document.cookie = `_GSIGN_TOKEN=${googleUser.credential}; expires=${expiresString}`;
    document.cookie = `_GSIGN_NAME=${token.name}; expires=${expiresString}`;
    document.cookie = `_GSIGN_EMAIL=${token.email}; expires=${expiresString}`;
    setLoggedIn(true);
  }

  const responseErrorGoogle = () =>  {
    setSnackBarEnabled(true);
    setSnackBarSeverity('error');
    setSnackBarMessage(`Sign In Error. Report it on #ask-quality-eng`);
  }

  React.useEffect(() => {
    if (loggedIn) {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectTo = searchParams.get('redirect_to');
      if (redirectTo) {
        const decoded = decodeURIComponent(redirectTo);
        window.location.assign(`${window.location.origin}/${decoded}`)
      } else {
        window.location.assign(`${window.location.origin}/home`)
      }
    }
  }, [loggedIn]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarEnabled(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <AppBar
          position="fixed"
          className={classes.appBar}
        >
        <Link to="/" className={classes.toolbarTitle}>
          <Typography variant="h6" noWrap>
            Quality-Engineering-UI
          </Typography>
        </Link>
        </AppBar>
      </Router>
      <div className={classes.googleSignIn}>
        <GoogleLogin
          theme='filled_blue'
          size='large'
          shape='pill'
          text='signin_with'
          useOneTap
          auto_select={
            new URLSearchParams(window.location.search).get('signed_out') == null
          }
          ux_mode='popup'
          onSuccess={responseSuccessGoogle}
          onError={responseErrorGoogle}
        />
        {loggedIn && <CircularProgress size={20} className={classes.circularProgress}/>}
      </div>
      <Snackbar open={snackBarEnabled} autoHideDuration={60000} onClose={handleCloseSnackBar} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <Alert severity={snackBarSeverity} onClose={handleCloseSnackBar}>{snackBarMessage}</Alert>
      </Snackbar>
    </React.Fragment>
  );
};
