import defaultConfig from './default';
import development from './development';
import stage from './stage';
import production from './production';
import developmentLocal from './development-local';

/**
 * checks if a given value is of type object
 * @param {*} item
 * @returns {Boolean}
 */
function isObject(item){
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * checks if a given value is of type Array
 * @param {*} item
 * @returns {Boolean}
 */
function isArray(item){
  return (item && Array.isArray(item));
}

/**
 * Copies all one or more source objects to a target object
 * @param {Object} target the target object
 * @param {...Object} sources the source objects
 * @return {Object}
 */
function nestedObjectAssign(target, ...sources){
  if (!sources.length)
      return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
      for (const key in source){
          if (isObject(source[key])){
              if (!target[key]) {
                  Object.assign(target, {[key]: {}});
              }
              nestedObjectAssign(target[key], source[key]);
          } else if (isArray(source[key])) {
            if (!target[key]) {
                Object.assign(target, {[key]: []});
            }

            target[key] = target[key].concat(source[key]);
          } else {
              Object.assign(target, {[key]: source[key]});
          }
      }
  }

  return nestedObjectAssign(target, ...sources);
}

let config;

const domain = window.location.hostname;

if (domain.includes('localhost')) {
  config = nestedObjectAssign(defaultConfig, developmentLocal);
} else if (domain.includes('development')) {
  config = nestedObjectAssign(defaultConfig, development);
} else if (domain.includes('stage')) {
  config = nestedObjectAssign(defaultConfig, stage);
} else {
  config = nestedObjectAssign(defaultConfig, production);
}

export default config;
